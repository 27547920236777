import { useEffect, useState } from 'react';
import axiosMain from '@/services/api/apis/api-board';
import { blogMain as Styled } from '@/styles';
import PostList from '@/components/blog/main/post-list';
import type { IPostExtend } from '@/interfaces/board-interfaces';
// import 'assets/styles/main.scss';

const Main = () => {
  const [postList, setPostList] = useState([] as IPostExtend[]);
  const [liHeight, setLiHeight] = useState<number>(200);
  // const [layoutHeight, setLayoutHeight] = useState(470);

  const getLiHeight = (height: number) => {
    if (height !== undefined) setLiHeight(+height);
  };

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    let size = 5;
    if (isMobile) {
      size = 3;
    }

    // if (isMobile) {
    //   const topHeight = (document.querySelector('.post-list') as HTMLDivElement).getBoundingClientRect().top;
    //   const footerHeight = (document.querySelector('footer') as HTMLElement).clientHeight;
    //
    //   setLayoutHeight(topHeight + footerHeight);
    // }

    // const size = Math.floor((document.body.clientHeight - layoutHeight - 100) / liHeight);
    // const size = 5;

    axiosMain
      .getRecentPost({ size })
      .then((response) => {
        setPostList(response.data?.list);
      })
      .catch((error: Error) => {
        console.log(error.message);
      });
  }, [liHeight]);

  return (
    <Styled.MainComponent>
      <Styled.TopHeader>
        <Styled.SubText>하루 한 두 시간은 좀 그렇고..</Styled.SubText>
        <Styled.MainText>
          {/*<Styled.MainTextSpan>알</Styled.MainTextSpan>기 쉽게&nbsp;*/}
          {/*<Styled.MainTextSpan>기</Styled.MainTextSpan>록하는&nbsp;*/}
          {/*<Styled.MainTextSpan>지</Styled.MainTextSpan>식*/}
          그냥&nbsp;<Styled.MainTextSpan>생각</Styled.MainTextSpan>&nbsp;날 때마다&nbsp;
          <Styled.MainTextSpan>기록</Styled.MainTextSpan>
        </Styled.MainText>
      </Styled.TopHeader>

      <Styled.Posts>Posts</Styled.Posts>

      <Styled.PostsList className="post-list">
        <PostList getLiHeight={getLiHeight} postList={postList} />
      </Styled.PostsList>
    </Styled.MainComponent>
  );
};

export default Main;
