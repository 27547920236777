import { introduce as Styled } from '@/styles';

const Welcome = () => {
  return (
    <Styled.IntroComponent>
      <Styled.IntroBig>안녕하세요. 프론트엔드 개발자 현동길입니다.</Styled.IntroBig>
      <Styled.IntroMessage>
        먼저 저의 기록 페이지에 방문해주신걸 진심으로 감사드립니다.
      </Styled.IntroMessage>

      <Styled.IntroInfo>
        <div>이 페이지는 크롬에 최적화되어 있습니다.</div>
        <div>또한 폰트나 화면이 작게 보일 수 있으니 PC 버전을 추천드립니다.</div>
      </Styled.IntroInfo>
    </Styled.IntroComponent>
  );
};

export default Welcome;
