interface CardProps {
  companyIndex: string;
  name: string;
  isOn: boolean;
  setCompany: (companyIndex: string) => void;
}

const CardCompany = ({ companyIndex, name, isOn, setCompany }: CardProps) => {
  return (
    <div
        id={ `c${ companyIndex }` }
        className={ isOn ? 'card on' : 'card' }
        onClick={ () => setCompany(companyIndex) }>
      <div className="light"></div>
      <span>{name}</span>
    </div>
  );
};

export default CardCompany;
