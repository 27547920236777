import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosBoard from '@/services/api/apis/api-board';
import { blogBoard as Styled } from '@/styles';
import BoardList from '@/components/blog/list/board-list';

const Board = () => {
  const location = useLocation();
  const [list, setList] = useState([]);
  const [mainCategory, setMainCategory] = useState('');
  const [tag, setTag] = useState('');

  useEffect(() => {
    const mainCategoryName = location.state?.mainCategory;
    const tagName = location.state?.tag;

    setMainCategory(mainCategoryName);
    setTag(tagName);

    const formData = new FormData();
    formData.append('tag', tagName);

    axiosBoard
      .boardList(formData)
      .then((resultBoard) => {
        setList(resultBoard.data?.list);
      })
      .catch((error: Error) => {
        console.log(error.message);
      });
  }, [location.state.tag, location.state.mainCategory, location.state.subCategory]);

  return (
    <>
      <Styled.MainComponent>
        <Styled.TopHeader>
          <Styled.SubText>{mainCategory}</Styled.SubText>
          <Styled.MainText>:: {tag} ::</Styled.MainText>
        </Styled.TopHeader>
      </Styled.MainComponent>

      <div className="wrap-board-list">
        <BoardList listData={list} board={tag} />
      </div>
    </>
  );
};

export default Board;
