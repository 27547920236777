import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import '@/assets/styles/list.scss';
import type { IBoardList } from '@/interfaces/board-interfaces';

const BoardList = ({ listData }: IBoardList) => {
  return (
    <div className="board-list">
      {listData.map((item: any) => {
        return (
          <ul key={item.idx}>
            <li className="title">
              <Link
                to={`/detail/${item.idx}`}
                state={{
                  idx: item.idx,
                  main: item.board,
                  tags: item.tags,
                }}
              >
                {item.title}
              </Link>
            </li>

            <li className="tags">
              {item.tags.split(',').map((item: string, index: number) => {
                return <span key={index}>{item}</span>;
              })}
            </li>

            <li className="date">{dayjs(item.regdate).format('YYYY.MM.DD')}</li>
          </ul>
        );
      })}
    </div>
  );
};

export default BoardList;
