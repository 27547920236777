import time from '@/utils/helpers/time';

const BlogInfo = () => {
  return (
    <footer>
      <div>Copyright 2021 - {time.getCurrentDate('YYYY')}&nbsp;&nbsp;&nbsp; donggil.hyun</div>
    </footer>
  );
};

export default BlogInfo;
