import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

const time = {
  /**
   * 현재 날짜를 가져온다.
   * @param type 표시할 날짜의 타입
   * @returns 현재 날짜
   */
  getCurrentDate: (type: string = 'YYYY.MM.DD') => dayjs().format(type),

  /**
   * 날짜의 millisecond를 가져온다.
   * @param {string} date millisecond로 변환할 날짜
   * @returns {number} millisecond
   */
  getMillisecond: (date: string): number => dayjs(date).millisecond(),

  /**
   * 날짜의 타입을 변환한다.
   * @param {string} type 변환할 타입
   * @param {string} date 날짜
   * @returns {String} 타입이 변환된 날짜
   */
  convertDateFormat: (type: string = 'YYYY.MM.DD', date?: string): string => {
    if (date) {
      return dayjs(date).format(type);
    }

    return dayjs().format(type);
  },
};

export default time;
