import axios from 'axios';

/**
 * axios 초기 설정
 */
const configAxios = axios.create({
  // baseURL: import.meta.env.REACT_APP_REST_URL,
  baseURL: import.meta.env.VITE_REST_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

configAxios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  // 요청 오류가 있는 작업 수행
  return Promise.reject(error);
});

// 응답 인터셉터 추가하기
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});

export default configAxios;
