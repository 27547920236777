import {Fragment, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '@/assets/styles/main.scss';
import time from '@/utils/helpers/time';
import type { IPost, IPostList } from '@/interfaces/board-interfaces';

/**
 * 메인의 post 목록 컴포넌트
 * @param param0 post 글 목록
 */
const PostList = ({ postList, getLiHeight }: IPostList | any) => {
  const navigate = useNavigate();
  const refLi = useRef<any>(null);

  useEffect(() => {
    const li = document.querySelectorAll('li');

    getLiHeight(li[0]?.clientHeight);
  });

  const showContent = (event: any, data: any) => {
    const contents = event.target.closest('.main-li');

    if (contents.classList.contains('detail')) {
      contents.classList.remove('detail');
    } else {
      contents.classList.add('detail');
    }

    navigate(`detail/${data.idx}`, {
      state: {
        idx: data.idx,
        main: data.subject,
        tags: data.tags,
        pointer: {
          width: contents.getBoundingClientRect().width,
          height: contents.getBoundingClientRect().height,
          top: contents.getBoundingClientRect().top,
          left: contents.getBoundingClientRect().left,
        },
      },
    });
  };

  return (
    <div className="post-list">
      <ul ref={refLi}>
        {postList &&
          postList.map((listData: IPost, index: number) => {
            return (
              <Fragment key={index}>
                <li className="main-li" onClick={(e) => showContent(e, listData)}>
                  <div>
                    <div className="post-title">{decodeURIComponent(listData.title)}</div>
                    <div className="post-content">
                      {listData.content_html
                        ?.replace(/&nbs/gi, '')
                        .replace(/p;/gi, '')
                        .replace(/(<([^>]+)>)/gi, '')}
                    </div>
                    <div className="post-board">
                      {listData.tags &&
                        listData.tags
                          .split(',')
                          .map((item: string, index: number) => (
                            <span key={index}>{item.trim()}</span>
                          ))}
                    </div>
                    <div className="post-date">
                      {time.convertDateFormat('YYYY MM DD ddd', listData.regdate)}

                      <Link
                        to="detail"
                        state={{ idx: listData.idx, main: listData.subject, tags: listData.tags }}
                        key={listData.idx}
                      >
                        <div className="more">더보기</div>
                      </Link>
                    </div>
                  </div>
                </li>
              </Fragment>
            );
          })}
      </ul>
    </div>
  );
};

export default PostList;
