import '@/assets/styles/soon.scss';

const Soon = () => {
  return (
    <div className="container">
      {/*<div className="rotating-light"></div>*/}
      {/*<div className="content">*/}
      {/*  <i className="fa-duotone fa-person-digging"></i>*/}
      {/*  <h1>COMING SOON..</h1>*/}
      {/*</div>*/}

      <div className="icon"><i className="fa-duotone fa-person-digging" /></div>
      <div className="content">
        <div className="str">UNDER CONSTRUCTION</div>
        <div className="sub_str">열심히 삽을 파는 중입니다.</div>
        <div className="info">COMING SOON ...</div>
      </div>
    </div>
  );
};

export default Soon;
