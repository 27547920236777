import MDEditor from '@uiw/react-md-editor';
import type { IDetailData } from '@/interfaces/board-interfaces';

const DetailList = ({ contentData }: IDetailData) => {
  return (
    <div className="detail">
      <div className="date">{contentData.regDate}</div>
      <div className="title">{contentData.title}</div>
      <div className="content">
        {contentData.content_markdown ? (
          <MDEditor.Markdown
            source={contentData.content_markdown}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DetailList;
