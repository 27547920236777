import BlogLayout from '@/components/blog-layout';
import Main from '@/pages/blog/main';
import Detail from '@/components/blog/detail/detail-post';
import Board from '@/pages/blog/board';

const blogRoute = [
  {
    path: '/',
    element: <BlogLayout />,
    children: [
      {
        path: '/',
        element: <Main />,
      },
      {
        path: '/detail/:id',
        element: <Detail />,
      },
      {
        path: '/board',
        element: <Board />,
      },
      {
        path: '/note',
        element: <Board />,
      },
    ],
  },
];

export default blogRoute;
