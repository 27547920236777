import styled from "@emotion/styled";
import '@/assets/styles/fonts.scss';

export const MainComponent = styled.div`
  background-color: #19333c;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  max-width: 1600px;
  overflow-x: auto;
  // padding: 30px 20px;
  font-family: "NEXON Lv1 Gothic OTF", serif;
  white-space: nowrap;
  margin: 0 auto;
`

export const CompanyName = styled.div`
  color: #8892b0;
  font-size: 17px;
  padding-bottom: 20px;
  padding-top: 2rem;
`;

export const Box = styled.div`
  box-sizing: border-box;
  // overflow-x: auto;
  overflow: hidden;
  position: relative;
  width: auto;
  height: auto;
  
  .button {
    background-color: #a5b5bd;
    border-radius: 0.7rem;
    bottom: 0.5rem;
    color: #19333c;
    height: 1.5rem;
    line-height: 1.6rem;
    font-size: 1rem;
    font-weight: 900;
    padding: 0.3rem 1rem;
    position: absolute;
    margin-top: -0.5rem;
    width: auto;
    text-align: center;
    
    &.deactive {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
  
  .prev {
    left: 0.5rem;
  }
  
  .next {
    right: 0.5rem;
  }
`;

export const ContentCard = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  color: #7c7c7c;
  // display: inline-flex;
  display: none;
  flex-direction: column;
  height: calc(100vh - 19rem);
  // margin-right: 20px;
  // max-width: 300px;
  padding: 2rem 1rem;
  margin-bottom: 3rem;
  transform: rotateY(0deg);
  width: 100%;
  
  .subject-name {
    color: #a5b5bd;
    padding-bottom: 0.5rem;
  }

  .project-title {
    color: #a5b5bd;
    font-size: 1.1rem;
    padding-bottom: 1.5rem;
  }

  .project-period {
    font-size: 0.85rem;
    padding-bottom: 1rem;
  }

  .project-skill {
    font-size: 0.95rem;
    padding-bottom: 1rem;
    
    .frontend::before {
      content: '[FE]';
      padding-right: 0.5rem;
    }

    .backend::before {
      content: '[BE]';
      padding-right: 0.5rem;
    }
  }

  .project-description {
    font-size: 1rem;
    
    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      
      li {
        box-sizing: border-box;
        font-size: 0.9rem;
        line-break: normal;
        line-height: 160%;
        list-style: none;
        padding-left: 0.7rem;
        position: relative;
        white-space: normal;
        width: 100%;
        word-break: keep-all;
        
        &::before {
          content: '-';
          position: absolute;
          left: 0;
        }
      }
      
    }
  }

  &.show {
    display: block;
  }

  &:last-child {
    margin-right: 0;
  }

  &.shake {
    transition: transform 0.5s; /* 추가 */
    transform: rotateY(360deg);
  }
`

export const WrapContent = styled.div`
  box-sizing: border-box;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
`

export const ContentMask = styled(WrapContent)`
  background-color: rgba(0, 0, 0, 0.8);
  height: inherit;
  width: inherit;
  z-index: 1;
`

export const ContentDetail = styled(WrapContent)`
  background-color: #19333c;
  border-radius: 7px;
  color: #a5b5bd;
  left: 50%;
  height: 90%;
  max-height: calc(100vh - 19rem);
  max-width: 700px;
  padding: 2rem 1rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: 2;

  .button-index {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 2rem;
    left: 0;
    position: absolute;
    width: 100%;
    justify-content: flex-end;
    
    .project-page {
      position: absolute;
      left: 1rem;
    }
    
    .prev-project,
    .next-project {
      width: 3rem;
      text-align: center;
      
      &.no-index {
        appearance: none;
      }
    }
  }
  
  .button-close {
    background-color: rgba(0, 0, 0, 0.25);
    font-size: 1.5rem;
    height: 2rem;
    justify-content: center;
    line-height: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    width: 2rem;
  }
  
  .name {
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }
  
  .period {
    font-size: 0.9rem;
    padding-bottom: 2rem;
  }
  
  .skill-front {
    font-family: 'IBMPlexSansKR', serif;
  }
  
  .skill-front::before {
    content: '[FE]';
    padding-right: 0.3rem;
  }
  
  .skill-back {
    font-family: 'IBMPlexSansKR', sans-serif;
  }

  .skill-back::before {
    content: '[BE]';
    padding-right: 0.3rem;
  }
  
  .detail {
    line-height: 160%;
    padding-top: 2rem;
    white-space: normal;
    word-break: keep-all;
  }

  //&::before {
  //  align-items: center;
  //  background-color: #fff;
  //  content: '<';
  //  color: black;
  //  display: flex;
  //  height: 1.5rem;
  //  justify-content: center;
  //  width: 1.5rem;
  //  position: absolute;
  //  top: 50%;
  //  left: calc(-1.5rem / 2);
  //}
`;

export const PageComponent = styled.div`
  box-sizing: border-box;
  display: none;
  height: 100%;
  padding: 2rem 0.5rem;
  width: 100%;
  
  .wrap-card {
    padding: 0.5rem;
  }
  
  .title-task {
    color: #aaa;
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  
  &.active {
    display: block;
  }
`;

export const Paging = styled.div`
  bottom: 0.5rem;
  color: #aaa;
  height: auto;
  position: fixed;
  right: 0.5rem;
  width: 2.5rem;
  
  div {
    border: 1px solid #777;
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    margin-bottom: 0.3rem;
    
    &.current {
      background-color: #777;
    }
  }
`;
