import { introduce as Styled } from '@/styles';

const Introduce = () => {
  return (
    <Styled.IntroComponent>
      <Styled.IntroMe>
        2013년부터 JavaScript와 jQuery를 이용하여 프론트엔드 업무와 함께 PHP와 MySQL을 활용한 백엔드 업무를 병행하며 성장했습니다.
        그리고 2019년에 jQuery에서 React로의 전환을 시작으로 현재는 Vue를 주력으로 하는 프로젝트에 참여하고 있습니다.
      </Styled.IntroMe>

      <Styled.IntroMe>
        프론트엔드와 백엔드 업무를 모두 경험했던 점으로 보았을 때, 화면을 보며 바로 결과물을 확인할 수 있는 프론트엔드 분야에 더 큰 매력을 느꼈고,
        그 결정은 자연스럽게 이루어졌습니다.
      </Styled.IntroMe>

      <Styled.IntroMe>
        개발에는 단일한 정답이 없다고 생각합니다. 문제가 발생했을 때 고민하고 실험을 거쳐 구현하며,
        이를 통해 점차적으로 개선해가며 새로운 방법을 찾는 것이 중요하다고 믿습니다.
        또한 동료들과의 리뷰를 통해 코드를 개선하고 완성도를 높이는 과정이 서비스에 애착을 갖게 만듭니다.
      </Styled.IntroMe>

      <Styled.IntroMe>
        저의 개발 목표는 항시 사용자가 편리하고 직관적으로 이용할 수 있는 웹 환경을 만드는 것입니다.
        이를 위해 노력하고 어려움을 겪더라도 시간을 투자하여 더 나은 결과물을 창출하고자 합니다.
      </Styled.IntroMe>
    </Styled.IntroComponent>
  );
};

export default Introduce;
