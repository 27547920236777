import React, {useEffect, useRef, useState} from 'react';
import {isNil} from "lodash-es";
import Welcome from "@/components/introduce/Welcome";
import Introduce from "@/components/introduce/introduce";
import History from "@/components/introduce/history";
import apiIntroduce from "@/services/api/apis/api-introduce";
import {introduceMain as styled} from '@/styles';
import ArrowLeft from '@/assets/images/arrow-left.svg';
import ArrowRight from '@/assets/images/arrow-right.svg';

interface Task {
  backend: string | null;
  description1: string | null;
  description2: string | null;
  description3: string | null;
  description4: string | null;
  detail: string;
  end_month: string;
  frontend: string | null;
  project_name: string;
  project_code?: number;
  start_month: string;
}

const Index = () => {
  document.body.style.overscrollBehaviorY = 'none';

  const animationSpeed: number = 500;
  const currentPage = useRef(0);
  const isScrolling = useRef(true);
  const [pageInfo, setPageInfo] = useState('');
  const [companies, setCompanies] = useState([]);

  const [companyName, setCompanyName] = useState('');
  const [taskList, setTaskList] = useState<Task[]>();
  const [taskDetail, setTaskDetail] = useState<Task>();

  const touchstartX = useRef(0)
  const touchstartY = useRef(0)
  const touchendX = useRef(0)
  const touchendY = useRef(0)

  const taskPage = useRef(0);
  const prevTaskIndex = useRef<number | null>(null);
  const nextTaskIndex = useRef<number | null>(null);

  interface Keys {
    [index: string]: string;
  }

  /**
   * key: 페이지 번호
   * value: 기업 코드
   */
  const keys: Keys = {
    '3': '5',
    '4': '4',
    '5': '3',
    '6': '2',
    '7': '1'
  };

  const scrollDisable = () => {
    isScrolling.current = false;
  }

  const scrollEnable = () => {
    setTimeout(() => {
      isScrolling.current = true;
    }, animationSpeed);
  }

  const [showContent, setShowContent] = useState(false)

  const shakeContent = (projectCode: number | undefined, length: number, page: number, event: React.MouseEvent<HTMLDivElement>) => {
    console.log(length, page + 1);

    const parent = (event.target as HTMLDivElement).closest('.card');

    if (parent) parent.classList.add('shake');

    setTimeout(() => {
      if (parent) parent.classList.remove('shake');

      apiIntroduce.postTaskDetail({ projectCode: Number(projectCode) })
        .then((res) => {
          setTaskDetail(res.data.list[0]);
          setShowContent(true);
        })
    }, 300);
  }

  /**
   * 모바일 버전에서 스크롤 시 페이징 설정
   * @param e 스크롤 이벤트
   */
  const handleMobileGesture = (e: TouchEvent) => {
    touchendY.current = e.changedTouches[0].screenY;
    const pages = document.querySelectorAll('.pages');

    if (!isScrolling.current) return;

    const deltaY = touchendY.current - touchstartY.current;

    if (deltaY >= 50 && currentPage.current > 0) {
      handleScroll(-1);

    } else if (deltaY <= -50 && currentPage.current < pages.length - 1) {
      handleScroll(1);

    } else if (Math.abs(touchendX.current - touchstartX.current) > 0) {
      // 왼쪽 또는 오른쪽으로 슬라이드 차단
      return false;
    }

    updatePageState();
  }

  /**
   * PC 버전에서 터치 스크롤 시 페이징 설정
   * @param e 터치 이벤트
   */
  function handlePcScroll(e: WheelEvent) {
    const pages = document.querySelectorAll('.pages');
    // setTaskPage(0);

    if (!isScrolling.current) return;

    if (e.deltaY < 0 && currentPage.current > 0) {
      handleScroll(-1);

    } else if (e.deltaY > 0 && currentPage.current < pages.length - 1) {
      handleScroll(1);
    }

    updatePageState();
  }

  /**
   * 스크롤 페이지 제어
   * @param pageNumber
   */
  const handleScroll = (pageNumber: number) => {
    scrollDisable();
    scrollEnable();
    currentPage.current += pageNumber;
  }

  /** 페이지 상태 업데이트 */
  const updatePageState = () => {
    const pages = document.querySelectorAll('.pages');

    pages.forEach((page) => page.classList.remove('active'));

    pages[currentPage.current].classList.add('active');
    setPageInfo(`${currentPage.current + 1} / ${pages.length}`);

    if (pages.length !== currentPage.current && !isNil(keys[currentPage.current])) {
      const requestData = {
        code: keys[currentPage.current],
      };

      apiIntroduce.postCompanyName(requestData)
        .then((res) => {
          setCompanyName(res.data.list[0].company_name);

          apiIntroduce.postTaskList(requestData).then((res) => {
            const groupTaskIndex: number[] = res.data.list.map((listData: { project_code: number; }) => listData.project_code);

            if (taskPage.current !== 0) {
              prevTaskIndex.current = groupTaskIndex[taskPage.current - 1];
            } else {
              prevTaskIndex.current = null;
            }

            if (taskPage.current !== groupTaskIndex.length - 1) {
              nextTaskIndex.current = groupTaskIndex[taskPage.current + 1];
            }

            setTaskList(res.data.list)
          });
        });
    }
  }

  const showTask = (type: string) => {
    type === 'next' ? taskPage.current++ : taskPage.current--;
  }

  const showPrevProject = () => {
    taskPage.current -= 1;
    console.log(prevTaskIndex.current);

    apiIntroduce.postTaskDetail({ projectCode: Number(prevTaskIndex.current) })
      .then((res) => {
        setTaskDetail(res.data.list[0]);
        setShowContent(true);
      })
  };

  const showNextProject = () => {
    taskPage.current += 1;
    console.log(nextTaskIndex.current);

    apiIntroduce.postTaskDetail({ projectCode: Number(nextTaskIndex.current) })
      .then((res) => {
        setTaskDetail(res.data.list[0]);
        setShowContent(true);
      })
  };

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const paragraph = document.querySelectorAll('.paragraph');

    apiIntroduce.getCompanyInfo()
      .then((res) => {
        setCompanies(res.data.count);

        const paging = [];
        for (let i = 0; i < res.data.count.length + paragraph.length; i++) {
          paging.push(<div key={i} className={currentPage.current === i ? 'indicator current' : 'indicator'} />);
        }

        // setTotalPage(paging);
        // setPageInfo(`${currentPage.current} / ${res.data.count.length + paragraph.length}`);
        setPageInfo(`1 / ${res.data.count.length + paragraph.length}`);
      });

    paragraph[0].classList.add('active');

    if (!isMobile) {
      window.addEventListener('wheel', (e) => handlePcScroll(e));

    } else {
      window.addEventListener('touchstart', (e) => {
        touchstartY.current = e.changedTouches[0].screenY;
      }, false);

      window.addEventListener('touchend', (e) => handleMobileGesture(e), false);
    }
  }, []);

  useEffect(() => {
    taskPage.current = 0;
  }, [currentPage.current]);

  return (
    <styled.MainComponent className="page-group">
      <styled.Paging>{pageInfo}</styled.Paging>

      { /** 인트로 */}
      <styled.PageComponent className="paragraph pages">
        <Welcome />
      </styled.PageComponent>

      { /** 소개글 */ }
      <styled.PageComponent className="paragraph pages">
        <Introduce />
      </styled.PageComponent>

      { /** 소속 기업 */ }
      <styled.PageComponent className="paragraph pages">
        <History/>
      </styled.PageComponent>

      <>
        {
          companies &&
          companies.map((_company, index) => {
            return (
              <styled.PageComponent key={index} className="pages">
                <div className="wrap-card">
                  <div className="title-task">진행 업무</div>
                  <styled.CompanyName>{companyName}</styled.CompanyName>
                  <styled.Box>
                    <div
                        className={ taskPage.current === 0 ? 'button prev deactive' : 'button prev' }
                        onClick={ () => taskPage.current !== 0 ? showTask('prev') : undefined }>
                      이전
                    </div>
                    <div
                        className={ taskPage.current === Object(taskList).length - 1 ? 'button next deactive' : 'button next' }
                        onClick={ () => taskPage.current !== Object(taskList).length - 1 ? showTask('next') : undefined }>
                      다음
                    </div>
                    {
                      taskList &&
                      taskList.map((task: Task, index: number) => {
                        return (
                          <styled.ContentCard
                            key={task.project_code}
                            className={taskPage.current === index ? 'card show' : 'card'}
                            onClick={(e) => shakeContent(task.project_code, taskList.length, taskPage.current, e)}>
                            <div className="project-title">{task.project_name}</div>
                            <div className="project-period">
                              <span className="subject-name">기간: </span>
                              <span>{task.start_month} ~ {task.end_month ?? '진행 중'}</span>
                            </div>
                            <div className="project-skill">
                              <div className="subject-name">사용 기술</div>
                              {
                                task.frontend && <div className="frontend">{task.frontend}</div>
                              }

                              {
                                task.backend && <div className="backend">{task.backend}</div>
                              }
                            </div>
                            <div className="project-description">
                              <div className="subject-name">설명</div>
                              <ul>
                                <li>{task.description1}</li>
                                {task.description2 && <li>{task.description2}</li>}
                                {task.description3 && <li>{task.description3}</li>}
                                {task.description4 && <li>{task.description4}</li>}
                              </ul>
                            </div>
                          </styled.ContentCard>
                        )
                      })
                    }
                  </styled.Box>
                </div>

                {
                  // 업무 상세 내용
                  showContent &&
                    <>
                      <styled.WrapContent>
                        <styled.ContentMask onClick={() => setShowContent(false)}/>
                        <styled.ContentDetail>
                          <div className="button-close" onClick={() => setShowContent(false)}>x</div>
                          <div className="name">{taskDetail?.project_name}</div>
                          <div className="period">{taskDetail?.start_month} ~ {taskDetail?.end_month ?? '진행 중'}</div>
                          { taskDetail?.frontend && <div className="skill-front">{taskDetail?.frontend}</div> }
                          { taskDetail?.backend && <div className="skill-back">{taskDetail?.backend}</div> }
                          <div className="detail">{taskDetail?.detail}</div>

                          <div className="button-index">
                            <div className="project-page">{ taskPage.current + 1 } of { Object(taskList).length }</div>
                            <div
                                className={ taskPage.current === 0 ? 'prev-project no-index' : 'prev-project'}
                                onClick={ taskPage.current !== 0 ? showPrevProject : undefined}>
                              <ArrowLeft stroke={ taskPage.current === 0 ? 'transparent' : '#a5b5bd' } />
                            </div>
                            <div
                                className={taskPage.current === Object(taskList).length - 1 ? 'next-project no-index' : 'next-project'}
                                onClick={taskPage.current !== Object(taskList).length - 1 ? showNextProject : undefined}>
                              <ArrowRight stroke={ taskPage.current !== Object(taskList).length - 1 ? '#a5b5bd' : 'transparent' } />
                            </div>
                          </div>
                        </styled.ContentDetail>
                      </styled.WrapContent>
                    </>
                }
              </styled.PageComponent>
            )
          })
        }
      </>
    </styled.MainComponent>
  )
}

export default Index
