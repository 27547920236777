import styled from '@emotion/styled';
import '@/assets/styles/main.scss';

export const MainComponent = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 940px;

  //@media (min-width: 280px) and (max-width: 757px) {
  //  width: 100vw;
  //}
`;

export const TopHeader = styled.div`
  margin-bottom: 100px;
  padding-top: 125px;
  text-align: center;

  @media (min-width: 280px) and (max-width: 757px) {
    margin-bottom: 0;
    padding-top: 6.5rem;
  }
`;

export const MainText = styled.div`
  color: #a5b5bd;
  font-family: 'Do Hyeon', sans-serif;
  font-size: 30px;

  @media (min-width: 280px) and (max-width: 757px) {
    font-size: 1.6rem;
  }
`;

export const MainTextSpan = styled.span`
  align-items: center;
  color: rgb(102, 178, 255);
  display: inline-flex;
  width: auto;
`;

export const SubText = styled.div`
  color: rgba(165, 181, 189, 0.3);
  font-size: 18px;
  font-weight: bold;

  @media (min-width: 280px) and (max-width: 757px) {
    font-size: 1rem;
  }
`;

export const Posts = styled.div`
  color: #a5b5bd;
  font-family:
    PlusJakartaSans-Bold,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 1.5;
  margin: 80px 0 0 0;

  @media (min-width: 280px) and (max-width: 757px) {
    font-size: 1.2rem;
    margin-top: 2rem;
    padding: 0 1rem;
  }
`;

export const PostsList = styled.div`
  padding-bottom: 150px;

  @media (min-width: 280px) and (max-width: 757px) {
    padding-bottom: 5rem;
  }
`;

export const Ul = styled.ul`
  padding: 0;

  a {
    text-decoration: none;
  }
`;

export const Li = styled.li`
  border-bottom: 1px solid #343a40;
  box-sizing: border-box;
  list-style: none;
  padding: 25px 0;
  position: relative;
`;

export const PostTitle = styled.div`
  color: #f8f9fa;
  display: -webkit-box;
  font-family: 'Do Hyeon', sans-serif;
  font-size: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  @media (min-width: 280px) and (max-width: 757px) {
    font-size: 1.3rem;
  }
`;

export const PostContent = styled.div`
  color: #a5b5bd;
  display: -webkit-box;
  font-size: 15px;
  height: auto;
  margin: 15px 0 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media (min-width: 280px) and (max-width: 757px) {
    -webkit-line-clamp: 1;
  }
`;

export const PostBoard = styled.div`
  color: #f8f9fa;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const BoardTags = styled.span`
  background-color: #525f7f;
  border-radius: 10px;
  font-size: 13px;
  margin-right: 10px;
  padding: 2px 7px;
`;

export const Date = styled.div`
  font-family: 'GmarketSans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #a5b5bd;

  @media (min-width: 280px) and (max-width: 757px) {
    display: flex;
    position: absolute;
    bottom: 1.5rem;
    right: 1rem;
  }
`;

export const More = styled.span`
  float: right;
  display: flex;
  align-items: flex-end;
  color: rgb(102, 178, 255);
  font-family: 'IBMPlexSansKR', sans-serif;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;

  @media (min-width: 280px) and (max-width: 757px) {
    display: none;
  }
`;
