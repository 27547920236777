import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '@/assets/styles/detail.scss';
import axiosBoard from '@/services/api/apis/api-board';
import time from '@/utils/helpers/time';
import DetailList from './detail-list';

const DetailPost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  console.log(location.state.pointer);

  const [contentData, setContentData] = useState({
    content_html: '',
    content_markdown: '',
    regDate: '',
    title: '',
  });

  useEffect(() => {
    const idx: string | undefined = params.id;
    const requestData = {
      idx,
    };

    // 상세 페이지 내용
    axiosBoard.postPostDetail(requestData).then((response) => {
      const detail = response.data?.detail;

      if (response.data?.result === 'success') {
        setContentData({
          title: detail.title,
          content_html: detail.content_html,
          content_markdown: detail.content_markdown,
          regDate: time.convertDateFormat('YYYY MM DD ddd', detail.regdate),
        });
      } else {
        return false;
      }
    });
  }, [location.state.idx, location.state]);

  return (
    <>
      <div className="wrap-detail">
        <div className="top-header">
          <div className="category-main">{location.state.main}</div>
          <div className="category-sub">:: {location.state.tags} ::</div>
        </div>
      </div>
      <div className="wrap-prev">
        <div className="btn-prev" onClick={() => navigate(-1)}>Back to main</div>
      </div>

      <div className="content-box">
        <DetailList contentData={contentData} />
      </div>
    </>
  );
};

export default DetailPost;
