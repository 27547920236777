import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export const MainComponent = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 900px;

  @media (min-width: 280px) and (max-width: 757px) {
    box-sizing: border-box;
    padding: 0 1rem;
  }
`;

export const TopHeader = styled.div`
  margin-bottom: 100px;
  padding-top: 125px;
  text-align: center;

  @media (min-width: 280px) and (max-width: 757px) {
    padding-top: 5rem;
    margin-bottom: 1rem;
  }
`;

export const MainText = styled.div`
  color: #a5b5bd;
  font-family: 'Do Hyeon', sans-serif;
  font-size: 30px;

  @media (min-width: 280px) and (max-width: 757px) {
    font-size: 1.5rem;
  }
`;

export const SubText = styled.div`
  color: rgba(165, 181, 189, 0.3);
  font-size: 18px;
  font-weight: bold;

  @media (min-width: 280px) and (max-width: 757px) {
    font-size: 1rem;
  }
`;

export const Prev = styled.div`
  color: rgb(102, 178, 255);
  cursor: pointer;
  font-size: 18px;
  margin: 0 auto 50px auto;
  padding-top: 10px;
  // width: 900px;
  width: 100%;
  max-width: 900px;

  @media (min-width: 280px) and (max-width: 757px) {
    box-sizing: border-box;
    margin: 0 auto 0.5rem auto;
    padding: 0 0.5rem;
  }
`;

export const PrevDiv = styled(Prev)`
  align-items: center;
  display: flex;
`;

export const Detail = styled.div`
  margin: 0 auto;
  // width: 850px;
  width: 100%;
  max-width: 900px;

  @media (min-width: 280px) and (max-width: 757px) {
    box-sizing: border-box;
    padding: 0 0.5rem 0 0.5rem;
  }
`;

const move = (position: any) => keyframes`
	//단계 별로 변화를 주는 코드
  from {
    top: ${position.top - 260}px;
    width: ${position.width};
  }

  to {
    top: 0;
    
    @media (min-width: 280px) and (max-width: 757px) {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ContentBox = styled.div<{ position: any }>`
  // animation: ${(props) => move(props.position)} 0.5s;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  border-bottom: 0;
  border-radius: 10px;
  box-sizing: border-box;
  left: 0;
  overflow: auto;
  position: relative;
  // top: ${(props: any) => props.value - 260}px;
  // width: calc(${(props) => props.position.width}px - 2rem);
  -webkit-backdrop-filter: blur(4px);

  @media (min-width: 280px) and (max-width: 757px) {
    border-radius: 0;
    // height: calc(100% - 8rem);
    padding: 1rem 0 5rem 0;
    width: 100%;
  }

  @media (min-width: 758px) {
    height: calc(100% - 500px);
    margin: 0 auto;
    overflow: auto;
    padding: 30px;
    width: 900px;
  }
`;

export const Date = styled(Detail)`
  color: #a5b5bd;
  font-family: 'GmarketSans', sans-serif;
  font-size: 15px;
  font-weight: 400;

  @media (min-width: 280px) and (max-width: 757px) {
    padding-bottom: 0;
  }
`;

export const Title = styled(Detail)`
  color: #f8f9fa;
  font-family: 'Do Hyeon', sans-serif;
  font-size: 36px;
  margin: 10px 0 30px 0;

  @media (min-width: 280px) and (max-width: 757px) {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    word-break: keep-all;
    padding-bottom: 0;
  }
`;

export const Content = styled(Detail)`
  color: #a5b5bd;
  //padding-bottom: 30px;
  //margin-bottom: 1000px;

  p {
    font-size: 15px;
  }
`;
