import styled from "@emotion/styled";

export const HistoryComponent = styled.div`
  color: #aaa;
  width: 100%;
  height: 100%;
  margin: 1rem auto;
  padding: .5rem;
  box-sizing: border-box;
`;

export const Company= styled.div`
  width: 100%;
  height: 40px;
  cursor: pointer;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;

  > div {
    border-radius: .7rem;
    float: left;
    height: 1.7rem;
    line-height: 0;
    margin: .5rem;
    text-align: center;
    width: calc((100% / 5) - 1rem);

    span {
      font-size: .8rem;
      left: 0;
      position: relative;
      top: .8rem;
    }

    &.on {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
`;

export const List = styled.div`
  height: auto; transition: all 2s;
  .bold { font-weight: bold; }
`;

export const Title = styled.div`
  color: #aaa;
  width: 100%;
  height: 50px;
  font-size: 1.5rem;
`;

export const Works = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: .7rem;
  box-sizing: border-box;
  color: #a5b5bd;
  float: left;
  font-size: .9rem;
  height: auto;
  margin-top: 1rem;
  padding: 1rem 0.7rem;
  text-align: left;
  width: 100%;
  
  #w2, #w3, #w4 { display: none; }
`;

export const WorkInWorks = styled.div`
  animation: fadein .3s;
  
  &.card-content {
    display: none;
    
    &.on {
      display: block !important;
    }
  }
`;

export const NameInWorks = styled.div`
  height: 2rem;
  font-size: 1.1rem;
  padding-bottom: 0;
`;

export const PeriodInWorks = styled.div`
  font-size: .9rem;
  height: 1.2rem;
  padding-bottom: 1rem;
`;

export const UnorderedList = styled.ul`
  display: table; padding-left: 0; width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const UlTwoCell = styled(UnorderedList)`
  li {
    width: 50%;
  }
`;

export const ListItems = styled.li`
  display: table-cell;
  height: auto;
  list-style: none;
  vertical-align: middle;
  width: 100%;

  .subject {
    font-size: 1rem;
    position: relative;
    top: 0;
  }

  .content {
    color: #7c7c7c;
    padding-left: 0.3rem;
  }
`;

export const LiWidth100 = styled(ListItems)`
  display: block;
  width: 100%;

  div {
    align-items: center;
    color: #7c7c7c;
    display: flex;
    line-height: 150%;
    position: relative;
    white-space: normal;
    word-break: keep-all;
    padding-left: 1.2rem;
   
    &:after {
      content: '-';
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &.content {
    padding-top: 0.5rem;
  }
`;
