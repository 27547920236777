import axios from '../index';

const apiIntroduce = {
  /** 기업 정보를 조회한다. */
  getCompanyInfo: () => axios.requestGet('/task/history-company'),

  /**
   * 기업명을 조회한다.
   * @param companyInfo 기업 정보
   */
  postCompanyName: (companyInfo: { code: string; }) => axios.requestPost('/task/company-info', companyInfo),

  /**
   * 진행했던 업무 목록을 조회한다.
   * @param companyCode 기업 코드
   */
  postTaskList: (companyCode: { code: string; }) => axios.requestPost('/task/task-list', companyCode),

  /** */
  postTaskDetail: (projectCode: { projectCode: number; }) => axios.requestPost('/task/task-detail', projectCode),
};

export default apiIntroduce;
