import styled from "@emotion/styled";

export const IntroComponent = styled.div`
  color: #aaa;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const IntroBig = styled.div`
  width: 100%;
  font-size: 1.2rem;
  padding-bottom: 2rem;
  transition: 1s;
`;

export const IntroMedium = styled.div`
  width: 100%;
  font-size: 1rem;
  padding-bottom: 2rem;
  transition: 1s;
`;

export const IntroBold = styled(IntroMedium)`
  font-weight: 700;
`;

export const IntroMessage = styled.div`
  width: 100%;
  font-size: 0.9rem;
  padding-bottom: 2rem;
  top: -.5rem;
  transition: 1s;
`;

export const IntroMe = styled.div`
  width: 80%;
  margin: 0 auto;
  font-size: 0.85rem;
  line-height: 170%;
  padding-bottom: 2rem;
  transition: 1s;
  word-break: keep-all;
  white-space: normal;
`;

export const IntroInfo = styled.div`
  font-size: .85rem;
  height: auto;
  line-height: 160%;
  transition: .5s;
  width: 100%;
`;
