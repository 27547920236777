import styled from '@emotion/styled';

export const MainComponent = styled.div`
  margin: 0 auto;
  width: 900px;

  @media (min-width: 280px) and (max-width: 757px) {
    width: 100vw;
  }
`;

export const TopHeader = styled.div`
  margin-bottom: 100px;
  padding-top: 125px;
  text-align: center;
`;

export const MainText = styled.div`
  color: #a5b5bd;
  font-family: 'Do Hyeon', sans-serif;
  font-size: 30px;
`;

export const SubText = styled.div`
  color: rgba(165, 181, 189, 0.3);
  font-size: 18px;
  font-weight: bold;
`;

export const WrapList = styled.div`
  border-radius: 10px;
  box-sizing: border-box;
  color: rgb(102, 178, 255);
  font-size: 13px;
  margin: 0 auto 50px auto;
  width: 850px;

  > div {
    align-items: center;
    display: flex;
  }
`;

export const ListUl = styled.ul`
  border-bottom: 1px solid rgba(218, 221, 230, 0.2);
  display: flex;
  padding: 20px;
  margin: 0;
  width: 100%;
`;

export const ListLi = styled.li`
  color: #a5b5bd;
  display: inline-flex;
  float: left;
  font-size: 15px;
  list-style: none;
  width: 100%;
`;

export const LiTitle = styled(ListLi)`
  font-family: 'IBMPlexSansKR', sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  width: 550px !important;

  a {
    color: #a5b5bd;
    text-decoration: none;

    &:hover {
      color: #66b2ff;
    }
  }
`;

export const LiTags = styled(ListLi)`
  align-items: center;
  display: flex;
  width: 200px !important;
`;

export const LiDate = styled(ListLi)`
  align-items: center;
  display: flex;
  font-family: 'GmarketSans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  justify-content: flex-end;
  width: 100px !important;
`;

export const TagName = styled.span`
  align-items: center;
  background-color: #525f7f;
  border-radius: 10px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 20px;
  margin-right: 7px;
  padding: 0 7px;
  width: auto !important;
`;
