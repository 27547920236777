import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './blog/header';
import Menu from './blog/menu';
import Footer from './blog/footer';
import { Outlet } from 'react-router-dom';
import Soon from '@/components/common/soon-component';
import axios from 'axios';

const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showHomePage, setShowHomePage] = useState<boolean | null>(null);

  useEffect(() => {
    const column = import.meta.env.VITE_SHOW_COLUMN;
    axios.get(`${import.meta.env.VITE_REST_URL}/settings`)
      .then((res) => {
        if (!res.data.list[0][column]) {
          // navigate('/');
          setShowHomePage(false);
        } else {
          setShowHomePage(true);
        }
      });
  }, [location, navigate, showHomePage]);

  return (
    <>
      {
        showHomePage === true
          ?
            (
              <>
                <Header />
                <Menu />
                <Outlet />
                <Footer />
              </>
            )
          :
            showHomePage !== null && !showHomePage
              ? <Soon />
              : <></>
      }

    </>
  );
};

export default MainLayout;
