import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import routerGroup from '@/routes';

declare global {
  interface Window {
    Kakao: any;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const title: HTMLTitleElement | null = document.querySelector('title');

if (title && import.meta.env.NODE_ENV === 'development') {
  title.innerText = ':: 똥그라미 🧑🏻‍💻‍💻️::';
} else if (title && import.meta.env.NODE_ENV !== 'development') {
  title.innerText = ':: 똥그라미 ::';
}

root.render(
  <>
    {/* <React.StrictMode> */}
    <RouterProvider router={routerGroup} />
    {/*<RouterProvider router={adminRouter} />*/}
    {/* </React.StrictMode> */}
  </>,
);
