import { useEffect, useState } from 'react';
import '@/assets/styles/menu.scss';
import { map as lodashMap } from 'lodash-es';
import { Link } from 'react-router-dom';
import apiBoard from '@/services/api/apis/api-board';

const Menu = () => {
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    apiBoard.getMenuItems().then((menuList) => {
      setMenuList(menuList.data?.list);
    });
  }, []);

  return (
    <div className="menu-bar menu">
      <div className="title-menu">MENU LIST</div>
      <>
        {menuList &&
          lodashMap(menuList, (menu: string[], index: number) => {
            return (
              <div key={index}>
                <div className="sub-menu">
                  <Link to={`/board?tag=${menu}`} state={{ tag: menu }}>
                    {menu}
                  </Link>
                </div>
              </div>
            );
          })}
      </>
    </div>
  );
};

export default Menu;
