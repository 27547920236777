interface Props {
  isMobile: boolean;
  showMenu: () => void;
}

const Navigation = ({ isMobile, showMenu }: Props) => {
  return (
    <div className="icon-menu" onClick={isMobile ? () => showMenu() : undefined}>
      {isMobile && <div className="icon-navigation material-symbols-outlined">menu</div>}
    </div>
  );
};

export default Navigation;
