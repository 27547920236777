import {useRef} from 'react';
import CardCompany from "./card-company";
import { history as Styled } from '@/styles';

const History = () => {
  const cardCompany = useRef<HTMLDivElement | null>(null);

  const setCompany = (index: string) => {
    const totalCompanyCards = (cardCompany.current as HTMLDivElement);
    const totalCards = totalCompanyCards.querySelectorAll('.card');
    const cardContent = document.querySelectorAll('.card-content');

    totalCards.forEach((res) => {
      res.classList.remove('on');
    });

    cardContent.forEach((res) => {
      res.classList.remove('on');
    })

    totalCompanyCards.querySelector(`#c${ index }`)?.classList.add('on');
    cardContent[+index - 1].classList.add('on');
  };

  return (
    <Styled.HistoryComponent>
      <Styled.Title>경력사항</Styled.Title>
      <>
        <Styled.List>
          <Styled.Company className="companies" ref={ cardCompany }>
            <CardCompany name="아이쿱" isOn={ true } companyIndex="1" setCompany={ setCompany } />
            <CardCompany name="이너아워" isOn={ false } companyIndex="2" setCompany={ setCompany } />
            <CardCompany name="하나애드" isOn={ false } companyIndex="3" setCompany={ setCompany } />
            <CardCompany name="이너아워" isOn={ false } companyIndex="4" setCompany={ setCompany } />
            <CardCompany name="CMG" isOn={ false } companyIndex="5" setCompany={ setCompany } />
          </Styled.Company>

          <Styled.Works>
            <Styled.WorkInWorks className="card-content on" id="w1">
              <Styled.NameInWorks>아이쿱</Styled.NameInWorks>
              <Styled.PeriodInWorks>2021.11 ~ 현재</Styled.PeriodInWorks>
              <>
                <Styled.UlTwoCell>
                  <Styled.ListItems>
                    <span className="subject">부서</span> :
                    <span className="content">개발팀</span>
                  </Styled.ListItems>
                  <Styled.ListItems>
                    <span className="subject">직위</span> :
                    <span className="content">차장</span>
                  </Styled.ListItems>
                </Styled.UlTwoCell>
                <Styled.UnorderedList>
                  <Styled.ListItems>
                    <span className="subject">사용 기술</span> :
                    <span className="content">HTML, SCSS, Vue</span>
                  </Styled.ListItems>
                </Styled.UnorderedList>
                <Styled.UnorderedList>
                  <Styled.LiWidth100>
                    <span className="subject">주요 업무</span>
                  </Styled.LiWidth100>
                  <Styled.LiWidth100 className="content">
                    <div>닥터바이스 베이직(시범 버전) 케어(앱) 웹뷰 페이지 개발</div>
                    <div>닥터바이스 베이직 클리닉(웹) 페이지 개발</div>
                    <div>닥터바이스 프로(상용 버전) 클리닉(웹) 페이지 개발</div>
                    <div>닥터바이스 프로 관리자 페이지 개발</div>
                  </Styled.LiWidth100>
                </Styled.UnorderedList>
              </>
            </Styled.WorkInWorks>
            <Styled.WorkInWorks className="card-content" id="w2">
              <Styled.NameInWorks>이너아워</Styled.NameInWorks>
              <Styled.PeriodInWorks>2018.01 ~ 2021.11</Styled.PeriodInWorks>
              <>
                <Styled.UlTwoCell>
                  <Styled.ListItems>
                    <span className="subject">부서</span> :
                    <span className="content">연구개발부</span>
                  </Styled.ListItems>
                  <Styled.ListItems>
                    <span className="subject">직위</span> :
                    <span className="content">과장</span>
                  </Styled.ListItems>
                </Styled.UlTwoCell>
                <Styled.UnorderedList>
                  <Styled.ListItems>
                    <span className="subject">사용 기술</span> :
                    <span className="content">HTML, CSS, jQuery, React, PHP, MySQL</span>
                  </Styled.ListItems>
                </Styled.UnorderedList>
                <Styled.UnorderedList>
                  <Styled.LiWidth100>
                    <span className="subject">주요 업무</span>
                  </Styled.LiWidth100>
                  <Styled.LiWidth100 className="content">
                    <div>기업 운영 사이트(모두의 고객센터, 트레딘) 관리 및 개발</div>
                    <div>기업 서비스 웹뷰 기반 모바일 애플리케이션 개발</div>
                    <div>고객사 요청 커스터마이징 기능 개발</div>
                  </Styled.LiWidth100>
                </Styled.UnorderedList>
              </>
            </Styled.WorkInWorks>
            <Styled.WorkInWorks className="card-content" id="w3">
              <Styled.NameInWorks>하나애드</Styled.NameInWorks>
              <Styled.PeriodInWorks>2017.01 ~ 2018.01</Styled.PeriodInWorks>
              <>
                <Styled.UlTwoCell>
                  <Styled.ListItems>
                    <span className="subject">부서</span> :
                    <span className="content">개발팀</span>
                  </Styled.ListItems>
                  <Styled.ListItems>
                    <span className="subject">직위</span> :
                    <span className="content">주임</span>
                  </Styled.ListItems>
                </Styled.UlTwoCell>
                <Styled.UnorderedList>
                  <Styled.ListItems>
                    <span className="subject">사용 기술</span> :
                    <span className="content">HTML, CSS, jQuery, PHP, MySQL</span>
                  </Styled.ListItems>
                </Styled.UnorderedList>
                <Styled.UnorderedList>
                  <Styled.LiWidth100>
                    <span className="subject">주요 업무</span>
                  </Styled.LiWidth100>
                  <Styled.LiWidth100 className="content">
                    <div>기업 관리 사이트 개발</div>
                    <div>협력사 광고 랜딩 페이지 게시 및 관리</div>
                    <div>협력사 및 광고팀 관련 요청 기능 개발</div>
                  </Styled.LiWidth100>
                </Styled.UnorderedList>
              </>
            </Styled.WorkInWorks>
            <Styled.WorkInWorks className="card-content" id="w4">
              <Styled.NameInWorks>이너아워</Styled.NameInWorks>
              <Styled.PeriodInWorks>2016.03 ~ 2017.01</Styled.PeriodInWorks>
              <>
                <Styled.UlTwoCell>
                  <Styled.ListItems>
                    <span className="subject">부서</span> :
                    <span className="content">연구개발부</span>
                  </Styled.ListItems>
                  <Styled.ListItems>
                    <span className="subject">직위</span> :
                    <span className="content">사원</span>
                  </Styled.ListItems>
                </Styled.UlTwoCell>
                <Styled.UnorderedList>
                  <Styled.ListItems>
                    <span className="subject">사용 기술</span> :
                    <span className="content">HTML, CSS, jQuery, PHP, MySQL</span>
                  </Styled.ListItems>
                </Styled.UnorderedList>
                <Styled.UnorderedList>
                  <Styled.LiWidth100><span className="subject">주요 업무</span></Styled.LiWidth100>
                  <Styled.LiWidth100 className="content">
                    <div>기업 홈페이지 및 관리자 페이지 개발</div>
                    <div>기업 서비스(ASoM, 수서니, 수선해, 써니크루) 개발</div>
                    <div>기업 서비스 웹뷰 기반 모바일 애플리케이션 개발</div>
                  </Styled.LiWidth100>
                </Styled.UnorderedList>

              </>
            </Styled.WorkInWorks>
            <Styled.WorkInWorks className="card-content" id="w5">
              <Styled.NameInWorks>CMG</Styled.NameInWorks>
              <Styled.PeriodInWorks>2013.05 ~ 2016.01</Styled.PeriodInWorks>
              <>
                <Styled.UlTwoCell>
                  <Styled.ListItems>
                    <span className="subject">부서</span> :
                    <span className="content">웹팀</span>
                  </Styled.ListItems>
                  <Styled.ListItems>
                    <span className="subject">직위</span> :
                    <span className="content">사원</span>
                  </Styled.ListItems>
                </Styled.UlTwoCell>
                <Styled.UnorderedList>
                  <Styled.ListItems>
                    <span className="subject">사용 기술</span> :
                    <span className="content">HTML, CSS, jQuery, PHP, MySQL</span>
                  </Styled.ListItems>
                </Styled.UnorderedList>
                <Styled.UnorderedList>
                  <Styled.LiWidth100>
                    <span className="subject">주요 업무</span>
                  </Styled.LiWidth100>
                  <Styled.LiWidth100 className="content">
                    <div>생협 홈페이지 리뉴얼 및 개발</div>
                    <div>지역 생협 홈페이지 관리</div>
                    <div>홍보 관련 게시물 게시 및 기능 개발</div>
                    <div>타 부서 요청 기능 개발</div>
                  </Styled.LiWidth100>
                </Styled.UnorderedList>
              </>
            </Styled.WorkInWorks>
          </Styled.Works>
        </Styled.List>
      </>
    </Styled.HistoryComponent>
  );
};

export default History;
